import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
  height: 69px;
`

const FilterList = styled.ul`
  display: flex;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  list-style: none;
`

const FilterItem = styled.li`
  flex: 1;
  text-align: center;
`

const Link = styled.button`
  text-transform: uppercase;
  font-family: ff-meta-web-pro-condensed, Arial Narrow;
  border: 0;
  background: none;
  font-weight: 700;
  font-size: 1.2em;
  color: ${props => props.active ? '#ec2821' : '#353535'};
  text-decoration: none;
  transition: transform .3s ease;
  position: relative;
  text-transform: uppercase;
  padding: 0;

  &:hover {
    color: ${props => props.active ? '#ec2821' : '#4a4a4a'};
  }

  &:focus:after {
    opacity: 0;
  }

  &:after {
    content: "";
    height: 2px;
    width: 100%;
    background-color: #ec2821;
    transition: transform .3s ease;
    position: absolute;
    bottom: -2px;
    left: 0;
    transform: ${props => props.active ? 'translateY(0)' : 'translateY(10px)'};
    opacity: ${props => props.active ? '1' : '0'};
  }
`

const Filter = ({ activeFilter, setActiveFilter }) => {
  const handleFilterClick = (filter, e) => {
    console.info('Defocusing', e.target)
    e.target.blur()
    setActiveFilter(filter)
  }

  return (
    <Wrapper>
      <FilterList>
        <FilterItem>
          <Link active={activeFilter === 'all'} title='All standards' onClick={handleFilterClick.bind(this, 'all')}>All</Link>
        </FilterItem>
        <FilterItem>
          <Link active={activeFilter === 'must'} title='All standards' onClick={handleFilterClick.bind(this, 'must')}>Must haves</Link>
        </FilterItem>
        <FilterItem>
          <Link active={activeFilter === 'should'} title='All standards' onClick={handleFilterClick.bind(this, 'should')}>Should haves</Link>
        </FilterItem>
        <FilterItem>
          <Link active={activeFilter === 'could'} title='All standards' onClick={handleFilterClick.bind(this, 'could')}>Could haves</Link>
        </FilterItem>
      </FilterList>
    </Wrapper>
  )
}

Filter.propTypes = {
  activeFilter: PropTypes.oneOf(['all', 'must', 'should', 'could']),
  setActiveFilter: PropTypes.func.isRequired
}

export default Filter

import React from 'react'
import styled from '@emotion/styled'
import device from '../device'

const Heading = styled.h1`
  font-family: ff-meta-web-pro-condensed, Arial Narrow;
  text-transform: uppercase;
  color: #fff;
  font-size: 1.5em;

  @media ${device.tablet} {
    font-size: 2em;
  }
`

const Title = (props) => <Heading>{props.children}</Heading>

export default Title

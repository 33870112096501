import styled from '@emotion/styled'
import Title from './Title'
import mmtLogo from '../assets/logo.svg'
import banner from '../assets/banner.jpg'
import device from '../device'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${banner}');
  background-size: cover;
  padding: 48px 0;
`

const TitleContainer = styled.div`
  padding-left: 8px;
`

const Logo = styled.img`
  display: block;
  height: 20px;
  margin-top: 3px;

  @media ${device.tablet} {
    height: 24px;
    margin-top: 5px;
  }
`

const Header = () => (
  <Wrapper>
    <Logo src={mmtLogo} />
    <TitleContainer>
      <Title>Engineering Standards</Title>
    </TitleContainer>
  </Wrapper>
)

export default Header

import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const StyledButton = styled.a`
  display: inline-block;
  text-align: center;
  border: none;
  background-size: 250% auto;
  font-size: 1.3rem;
  font-family: ff-meta-web-pro-condensed, Arial Narrow;
  font-weight: 700;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: .8rem 3rem;
  cursor: pointer;
  overflow: hidden;
  line-height: 1.25rem;
  min-width: 138px;
  text-transform: uppercase;
  text-decoration: none !important;
  transition: .5s;
  background-image: linear-gradient(135deg,#ec2821,#ec2821 50%,#c91811 51%,#c91811);
  letter-spacing: normal;
  line-height: 33px;
  color: ${props => props.appearance === 'primary' ? '#fff' : '#000'};
  background-color: ${props => props.appearance === 'primary' ? '#ec2821' : '#fff'};
  background-image: ${props => props.appearance === 'primary' ? 'linear-gradient(135deg,#ec2821,#ec2821 50%,#c91811 51%,#c91811)': 'linear-gradient(135deg,#fff,#fff 50%,#e6e6e6 51%,#e6e6e6)'};

  &:hover {
    background-position: 99%;
  }
`

const Button = ({ appearance = 'primary', href, children }) => (
  <StyledButton appearance={appearance} href={href}>{children}</StyledButton>
)

Button.propTypes = {
  appearance: PropTypes.oneOf(['primary', 'secondary'])
}

export default Button

import styled from '@emotion/styled'
import device from '../device'

const FooterContainer = styled.footer`
  width: 100%;
  padding: 52px 16px;
  text-align: center;
`

const Link = styled.a`
  font-family: ff-meta-web-pro, Calibri;
  font-weight: 700;
  text-decoration: none;
  color: #9b9b9b;
  font-size: 1.2em;
  letter-spacing: -.5px;
`

const FooterList = styled.ul`
  list-style: none;

  &:last-child {
    border-right: 0;
  }
`

const FooterListItem = styled.li`
  position: relative;
  display: inline-block;
  padding: 0 16px;

  @media ${device.tablet} {
    border-right: 1px solid #9b9b9b;
  }

  &:before {
    content: "";
    position: absolute;
    width: calc(100% - 32px);
    margin: 0 16px;
    height: 1px;
    left: 0;
    background-color: #9b9b9b;
    transition: all .3s ease-in-out 0s;
    bottom: -.5rem;
  }

  &:hover:before {
    opacity: 0;
    width: calc(100% - 16px);
    margin: 0 8px;
  }

  &:last-child {
    border-right: 0;
  }
`

const Footer = () => (
  <FooterContainer>
    <FooterList>
      <FooterListItem>
        <Link href="mailto:hello@mmtdigital.co.uk">hello@mmtdigital.co.uk</Link>
      </FooterListItem>
      <FooterListItem>
        <Link href="https://mmtdigital.co.uk/careers">Careers</Link>
      </FooterListItem>
    </FooterList>
  </FooterContainer>
)

export default Footer

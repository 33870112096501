import { useState } from 'react'
import styled from '@emotion/styled'
import Header from './components/Header'
import Footer from './components/Footer'
import Filter from './components/Filter'
import Contribute from './components/Contribute'
import standards from './standards.json'
import device from './device'

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 16px;
  margin: 0 auto;
`

const StandardsContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  margin: 56px 0;

  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media ${device.desktop} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const Standard = styled.p`
  width: 100%;
  height: 200px;
  border-top: 6px solid;
  background-color: #fff;
  border-color: ${props => props.must ? '#b3d495' : props.should ? '#b7cde8' : '#efe394'};
  padding: 16px;
  font-size: 1.2em;
  box-shadow: 0 2px 2px 0 transparent, 0 2px 7px 0 rgba(0,0,0,.2);
  margin: 0 auto;

  /*&:hover {
    cursor: pointer;
    background-color: #f5f5f5;
    transition: all .3s;
  }*/
`

const StandardType = styled.p`
  font-family: ff-meta-web-pro-condensed, Arial Narrow;
  font-size: .8em;
  text-transform: uppercase;
  color: #222;
  margin-bottom: 16px;
`

const App = () => {
  const [activeFilter, setActiveFilter] = useState('all') // must, should, could
  const handleStandardClick = (e) => {
    e.preventDefault()
    console.info('Clicked on standard')
  }

  return (
    <>
      <Header />
      <Filter activeFilter={activeFilter} setActiveFilter={setActiveFilter} />

      <Container>
        <StandardsContainer>
          {(activeFilter === 'must' || activeFilter === 'all') && standards.must.map(standard => (
            <Standard key={`must-${standard}`} onClick={handleStandardClick} must>
              <StandardType>MUST HAVE</StandardType>
              <p>{standard}</p>
            </Standard>
          ))}
          {(activeFilter === 'should' || activeFilter === 'all') && standards.should.map(standard => (
            <Standard key={standard}  onClick={handleStandardClick} should>
              <StandardType>SHOULD HAVE</StandardType>
              <p>{standard}</p>
            </Standard>
          ))}
          {(activeFilter === 'could' || activeFilter === 'all') && standards.could.map(standard => (
            <Standard key={standard}  onClick={handleStandardClick} could>
              <StandardType>COULD HAVE</StandardType>
              <p>{standard}</p>
            </Standard>
          ))}
        </StandardsContainer>
      </Container>

      <Contribute />
      <Footer />
    </>
  )
}

export default App

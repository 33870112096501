import styled from '@emotion/styled'
import Title from './Title'
import Button from './Button'

const Wrapper = styled.div`
  width: 100%;
  background-color: #333;
  padding: 4rem 16px;
  text-align: center;
`

const SubTitle = styled.p`
  color: #fff;
  padding: 32px 0;
  font-size: 1.2em;
  line-height: 1.5em;
`

const Contribute = () => (
  <Wrapper>
    <Title>Help us shape our standards</Title>
    <SubTitle>We're building experiences together, and the standards we hold ourselves to should be defined together.</SubTitle>
    <Button appearance='secondary' href='/contribute'>Contribute</Button>
  </Wrapper>
)

export default Contribute
